'use strict';

function supremeWidgetsTabs() {
    /* Tabs widget */
    const tabWrapElements = document.querySelectorAll('.sw-tabwrap');

    tabWrapElements.forEach((tabWrapElement) => {
        const tabLinks = tabWrapElement.querySelectorAll('.sw-tabs li a');

        tabLinks.forEach((tabLink) => {
            tabLink.addEventListener('click', function (e) {
                e.preventDefault();

                const parentLi = this.parentElement;
                const tabWrap = parentLi.parentElement.parentElement;

                // Remove 'current' class from all 'li' elements and 'div div' elements
                const allLiElements = tabWrap.querySelectorAll('li');
                const allDivDivElements = tabWrap.querySelectorAll('div div');

                allLiElements.forEach((li) => { return li.classList.remove('current'); });
                allDivDivElements.forEach((div) => { return div.classList.remove('current'); });

                // Add 'current' class to the clicked 'li' element and its corresponding tab
                parentLi.classList.add('current');
                const currentTab = this.getAttribute('href');
                document.querySelector(currentTab).classList.add('current');
            });
        });
    });
}
supremeWidgetsTabs();

(function ($) {
    $(document).ready(($) => {

        /**
         * Handles the contact form ajax send.
         * Depends on Google reCaptcha.
         *
         */
        function supremeWidgetsContactForm() {
            $('.sw-contact-form').each(function () {

                $(this).on('submit', function (event) {

                    // Prevent default
                    event.preventDefault();

                    // Variables
                    const form = $(this);
                    const formData = $(form).serialize();
                    const captcha = form.find('.google-inline-captcha');
                    const loader = form.find('.form-loader');
                    const messageBox = form.find('.form-message-box');

                    loader.addClass('show');

                    // first check if captcha is there/API keys are provided in theme options
                    if (captcha.length === 1) {

                        captcha.find('.btn-close').on('click', function () {
                            $(this).parents(captcha).removeClass('show form-success');
                        });

                        $.ajax({
                            url: SS_VARS.ajax_url,
                            type: 'POST',
                            data: {
                                action: 'send_message_widget',
                                data: formData,
                            },
                            dataType: 'json',
                        }).done((data) => {

                            loader.removeClass('show');
                            captcha.addClass('show').removeClass('form-success');

                            if (data.google_response.success === true) {

                                $(captcha).removeClass('show').addClass('form-success');
                                $(captcha).find('.form-messages').html(data.message);

                                form.find('input[name="name"]').val('');
                                form.find('input[name="email"]').val('');
                                form.find('textarea[name="content"]').val('');

                            } else {
                                $(captcha).find('.form-messages').html(data.message);
                            }

                            $(messageBox).removeClass('error');

                        }).fail(() => {
                            // If error
                        });

                    }

                });

            });
        }
        supremeWidgetsContactForm();

        /**
         * Handles the newsletter form ajax subscription.
         *
         * Depends on Google reCaptcha. https://usX.api.mailchimp.com/3.0/lists/{list_id}/members/
         *
         */
        function supremeWidgetsNewsletter() {
            $('.sw-signup-mailchimp').each(function () {

                $(this).on('submit', function (event) {

                    // Prevent default
                    event.preventDefault();

                    // Variables
                    const form = $(this);
                    const formData = $(form).serialize();
                    const captcha = form.find('.google-inline-captcha');
                    const loader = form.find('.form-loader');
                    const messageBox = form.find('.form-message-box');

                    loader.addClass('show');

                    // first check if captcha is there/API keys are provided in theme options
                    if (captcha.length === 1) {

                        captcha.find('.btn-close').on('click', function () {
                            $(this).parents(captcha).removeClass('show form-success');
                        });

                        $.ajax({
                            url: SS_VARS.ajax_url,
                            type: 'POST',
                            data: {
                                action: 'subscribe_mailchimp',
                                data: formData,
                            },
                            dataType: 'json',
                        }).done((data) => {

                            loader.removeClass('show');
                            captcha.addClass('show').removeClass('form-success');

                            if (data.google_response.success === true) {

                                $(captcha).removeClass('show').addClass('form-success');
                                $(captcha).find('.form-messages').html(data.message);

                                form.find('input[name="firstname"]').val('');
                                form.find('input[name="lastname"]').val('');
                                form.find('input[name="email"]').val('');

                            } else {
                                $(captcha).find('.form-messages').html(data.message);
                            }

                            $(messageBox).removeClass('error');

                        }).fail(() => {
                            // If error
                        });

                    }

                });

            });
        }
        supremeWidgetsNewsletter();

    });

}(jQuery));

/* Mediaelement */
function swMediaelementInit() {

    const settings = window._wpmejsSettings || {};
    settings.features = settings.features || mejs.MepDefaults.features;
    settings.features.push('exampleclass');

    MediaElementPlayer.prototype.buildexampleclass = function (player) {
        player.container.addClass('sw-mejs-container');
    };

}
swMediaelementInit();
